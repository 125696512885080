<template>
  <ion-page class="page scrollable story-world-page position-relative">
    <Transition>
      <div v-if="loading" class="loading-content position-absolute">
        <HeaderLoading />
      </div>
      <div v-else-if="world" class="content">
        <Header :world="world" :isdraft="isDraft" />
        <story-world-actions v-if="hasSections" class="my-3" :sections-collapsed="sectionsCollapsed" />
        <story-world-section :world="world" />
      </div>
    </Transition>
  </ion-page>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import Header from './components/StoryWorldHeader.vue';
import StoryWorldSection from './components/StoryWorldSection.vue';
import StoryWorldActions from './components/StoryWorldActions.vue';
import HeaderLoading from './components/StoryWorldHeaderLoading.vue';
import { WorldsInfo } from '@/shared/types/static-types';
import { storyWorldStore } from '@/shared/pinia-store/storyworld';
import { unreorderableCreatorKeys } from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';

const { world, loading, loadStoryWorldBySlug, loadDraftWorldById, sectionsCollapsed } = storyWorldStore();
const route = useRoute();
const { user: loggedInUser, isAuthenticated } = authStore();
const picture = ref('');
const title = ref('');
const description = ref('');
const pageTitle = ref('');
const slug = ref('');
const allowCrawlers = ref(true);
const url = ref(`https://characterhub.com${route.path}`);

const possibleSections = computed(() => {
  return omit(world?.value, unreorderableCreatorKeys as string[]);
});

const reorderedSections = computed(() => {
  const order: string[] = world?.value?.order || Object.keys(possibleSections.value);
  const reorderedSections = order.reduce((acc: WorldsInfo, current) => {
    return {
      ...acc,
      [current]: possibleSections.value[current],
    };
  }, {} as WorldsInfo);

  return reorderedSections;
});

const hasSections = computed(() => {
  return Object.keys(reorderedSections.value).length > 0;
});

const triggerWarning = async () => {
  if (world.value && loggedInUser.value.id !== world.value.user?.id && !isEmpty(world.value.triggerwarning)) {
    const alert = await alertController.create({
      cssClass: 'char-warning-alert',
      header: 'Warning',
      backdropDismiss: false,
      message: world.value.triggerwarning,
      translucent: false,
      buttons: [
        {
          text: 'Go Back',
          handler: () => {
            const router = useRouter();
            router.go(-1);
          },
        },
        { text: 'Continue' },
      ],
    });
    await alert.present();

    await alert.onDidDismiss();
  }
};

const nsfwWarning = async () => {
  if (world.value) {
    if (!isAuthenticated.value && (world.value.is_nsfw || world?.value?.user?.is_nsfw)) {
      const alert = await alertController.create({
        cssClass: 'char-warning-alert',
        header: 'Warning',
        message: `You are about to see potentially mature content, by proceeding you confirm you are 18 years of age or older.`,
        buttons: [
          {
            text: 'Continue',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'mx-auto',
          },
        ],
        backdropDismiss: false,
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === 'ok') {
        triggerWarning();
      }
    } else {
      triggerWarning();
    }
  }
};

const isDraft = computed(() => {
  return route.name === 'world-draft-details';
});

await useChAsyncData(async () => {
  if (isDraft.value) {
    const { id } = route.params;
    const idAsString = id as string;
    await loadDraftWorldById(idAsString);
    if (process.client) await triggerWarning();
    return;
  }
  const { slug: inSlug } = route.params;
  slug.value = String(inSlug);
  await loadStoryWorldBySlug(slug.value);
  description.value = `Read more about ${world?.value?.description} on CharacterHub!`;
  title.value = `${world?.value?.name}`;
  pageTitle.value = `${title.value} - CharacterHub`;
  picture.value = world?.value?.cropped_profile_img || world?.value?.cover_image;
  allowCrawlers.value = world?.value?.privacy === 'P' && (world?.value?.reaction_counts?.total_count || 0) > 200;

  useChHead(pageTitle, title, description, url, picture, allowCrawlers);

  if (world.value?.type === 'socialspace') {
    const router = useRouter();
    router.replace({ name: 'social-space-details', params: { slug: slug.value } });
  }
  if (process.client) await nsfwWarning();
});
</script>

<style lang="sass" scoped>
.loading-content
  width: calc(100% - 1.5rem)
.story-world-page
  overflow: hidden
  padding: 24px 12px !important
  // margin-top: -40px !important
  min-height: 628px
.v-enter-active,
.v-leave-active
  transition: opacity 0.5s ease

.v-enter-from,
.v-leave-to
  opacity: 0
</style>
