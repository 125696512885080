<template>
  <div class="world-locations w-100 ml-1">
    <div class="d-flex location-list h-100">
      <div class="d-flex pb-4 card-container flex-wrap position-relative">
        <ion-card v-for="(world, index) in world_locations" :key="index" class="location-card ml-0 mt-3 mb-0 mr-3">
          <div class="clickable-item">
            <router-link :to="{ name: 'world-details', params: { slug: world.slug } }" class="text-black">
              <div class="h-100">
                <div class="d-flex justify-content-center">
                  <img loading="lazy" v-image class="img" :src="resizeUpload(world.cover_image, '190h')" />
                </div>

                <div class="name d-flex justify-content-start mt-3 mx-3 clickable-item text-black">
                  {{ world.name }}
                </div>
              </div>
            </router-link>

            <div class="d-flex justify-content-end p-2">
              <div class="actions-inner">
                <Reaction
                  type="world"
                  :reactions="world.reaction_counts"
                  :user-reaction="world.user_reaction"
                  :left-indent-location="true"
                  @click.stop
                  @changed="(reaction) => reactionChanged(reaction.key, world, reaction.isInstant)"
                >
                  <ion-button class="inline-button icon-button clickable d-file">
                    <div class="d-flex align-items-center justify-content-center">
                      <i
                        class="text-primary mr-1 ti-thumb-up"
                        :class="{
                          'text-secondary': !!world.user_reaction && world.user_reaction.reaction,
                        }"
                      />
                      <span
                        class="reaction-count"
                        :class="[
                          !!world.user_reaction && world.user_reaction.reaction ? 'text-secondary' : 'text-primary',
                        ]"
                        >{{ get(world, 'reaction_counts.total_count') || 0 }}</span
                      >
                    </div>
                  </ion-button>
                </Reaction>

                <ion-button class="inline-button icon-button ml-2">
                  <div class="d-flex align-items-center justify-content-center">
                    <ion-icon class="text-primary mr-1 user-icon" :icon="chatboxEllipsesOutline" />
                    <span color=" #3dc2ff" class="reaction-count text-primary">{{ 0 }}</span>
                  </div>
                </ion-button>
              </div>
            </div>
          </div>
        </ion-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { chatboxEllipsesOutline } from 'ionicons/icons';
import { resizeUpload } from '@/shared/utils/upload';
import Reaction from '@/shared/components/Reaction/index.vue';
import store from '@/shared/store';
import { WorldsInfo } from '@/shared/types/static-types';

const emits = defineEmits(['afterReaction']);

const reactionChanged = async (reaction: string, social: any, isInstant = false) => {
  const reactionResp = await store.dispatch('WorldsModule/react', { reaction, world: social, isInstant });
  emits('afterReaction', { reactResp: reactionResp, social });
};

const props = defineProps({
  subworlds: {
    type: Array,
  },
});

const world_locations = toRef(props, 'subworlds') as Ref<WorldsInfo[]>;
</script>

<style lang="sass" scoped>
.user-icon
  font-size: 16px
.actions
  position: absolute
  bottom: 1rem
  right: 1rem
.actions-inner
  width: 100px
  display: flex
  .inline-button
    --background: transparent
    &:not(:first-of-type)
      margin: 0 5px
.world-locations
  overflow-x: auto
.name
  font-size: 18px
  font-weight: bold
  cursor: pointer
  word-break: break-word
  margin: auto 0.25rem
  .name:hover
    opacity: 0.7
.reaction-count
  font-size: 18px
  font-weight: bold
.see-all-text
  color: #214163
  display: inline-block
.title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px
.location-card
  overflow: unset
  width: calc(50% - 18px)
  min-width: 200px !important
  text-break: break-word
  border-radius: 10px
  img
    border-top-left-radius: 10px
    border-top-right-radius: 10px

.img
  width: 347px
  height: 170px
  object-fit: cover
  @media(max-width: 500px)
    height: 175px
    width: 330px
    aspect-ratio: 1/1
    object-fit: cover
.location-list
  overflow-x: hidden
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none
.card-container
  margin: 0 auto
  justify-content: center
@media(max-width: 500px)
  .location-card
    width: calc(100% - 8px)
    min-width: 100px !important
  .actions
    bottom: 0.3rem
    right: 0.25rem
</style>
