<template>
  <div class="story-world-header position-relative">
    <div class="cover">
      <MatureTag v-if="isNsfw" size="md" class="mature" />
      <img loading="lazy" v-image :src="resizeUpload(cover, '800w')" class="image" />
    </div>

    <div class="rest position-relative">
      <div class="name d-flex justify-content-center">
        <div>
          <span v-if="world?.privacy === 'M'">🔒&nbsp;</span><span v-if="world?.privacy === 'U'">🔗&nbsp;</span
          >{{ name }}
        </div>
        <ion-badge v-if="isdraft" class="ml-2 mt-1 badge" color="secondary">Draft</ion-badge>
      </div>
      <div v-if="quote" class="quote">"{{ quote }}"</div>
      <div v-if="tags.length" class="tags mx-auto d-flex justify-content-center flex-wrap">
        <div
          v-for="(tag, index) of tags"
          :key="index"
          class="tag d-flex mt-1 justify-content-center align-items-center bg-white text-black"
          @click="openTagSearch(tag)"
        >
          #{{ truncateText(tag, 20) }}
        </div>
      </div>
      <div class="actions mx-auto d-flex justify-content-center">
        <div class="inner-actions d-flex w-100 top-row align-items-center justify-content-center">
          <ion-button v-if="isWorldOwner" class="edit-btn" @click="editWorld">
            <inline-svg class="icon mr-1" src="/icons/pencil.svg" height="16px" width="16px" />
            <div class="edit-text ml-1">Edit Information</div>
          </ion-button>
          <Reaction
            v-if="!isdraft"
            type="world"
            class="one-button"
            :reactions="reactions"
            :user-reaction="userReaction"
            :left-indent-home="true"
            @changed="(reaction) => reactionChanged(reaction.key, reaction.isInstant)"
          >
            <ion-custom-button v-show="!userReaction" class="button">React</ion-custom-button>
            <ion-custom-button v-show="userReaction" class="button" color="secondary">{{
              userReactionText
            }}</ion-custom-button>
          </Reaction>
          <SubmitFanWork
            v-if="!isWorldOwner && isAuthenticated && route.name !== 'world-draft-details'"
            :submitted-for-id="id"
            submitted-for-type="world"
            class="one-button mx-1"
          >
            <ion-custom-button class="fanart one-button">Submit Fanart</ion-custom-button>
          </SubmitFanWork>
          <bookmark-picker
            v-if="isAuthenticated && world?.id && !isdraft"
            :object-id="id"
            content-type="world"
            in-button
            class="bookmark"
          />
        </div>
      </div>
      <div class="justify-content-between">
        <div class="owner text-black d-flex align-items-center">
          <span class="weight-500">Owned by:</span>
          <router-link
            :class="{
              'text-color-username': usernameColor(world?.user) && !usernameColor(world?.user).startsWith('#'),
              'bold-username': usernameColor(world?.user),
            }"
            :style="
              !usernameColor(world?.user)
                ? ''
                : usernameColor(world?.user).startsWith('#')
                ? `color: ${usernameColor(world?.user)} !important`
                : `background-image: ${usernameColor(world?.user)} !important`
            "
            class="text d-flex align-items-center"
            :to="{ name: 'profile', params: { username: username } }"
          >
            <span class="mx-1"> @{{ username }}</span>

            <ProfileBadge :badges="get(world?.user.customize_profile, 'badges') || []" :show-small-badge="true" />
          </router-link>
        </div>
        <div></div>
        <ion-button v-if="!isdraft && !isWorldOwner" class="header-popover-button d-block" @click="worldDropdownToggle"
          ><i class="ti-more-alt"
        /></ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Reaction from '@/shared/components/Reaction/index.vue';
import { worldReactionsNames } from '@/shared/statics/reactions';
import SubmitFanWork from '@/shared/components/FanWork/SubmitFanWork.vue';
import { storyWorldStore } from '@/shared/pinia-store/storyworld';
import BookmarkPicker from '@/shared/components/BookmarkPicker.vue';
import MatureTag from '@/shared/components/MatureTag.vue';
import StoryWorldAndSocialSpacePopover from '../popovers/StoryWorldAndSocialSpacePopover.vue';
import { popovers } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { truncateText } from '@/shared/utils/string';

const { user, isAuthenticated } = authStore();
const { react } = storyWorldStore();

const router = useRouter();
const props = defineProps({
  world: {
    type: Object,
    default: {},
  },
  isdraft: {
    type: Boolean,
  },
});

const isdraft = toRef(props, 'isdraft');

const openTagSearch = (tagText: string) => {
  const hashText = '#';
  tagText = hashText.concat(tagText);
  router.push({ name: 'search', query: { term: tagText, tab: 'all' } });
};

const editWorld = () => {
  const name = isdraft.value ? 'draft-story-world' : 'edit-world';
  router.push({ name, params: { id: world.value.id } });
};

const route = useRoute();

const world = toRef(props, 'world');
const isNsfw = computed(() => world.value.is_nsfw);

const id = computed(() => {
  return world?.value?.id;
});

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

const username = computed(() => {
  return world?.value?.user?.username || 'user';
});

const cover = computed(() => {
  return world.value.cover_image;
});

const name = computed(() => {
  return world?.value?.name;
});

const quote = computed(() => {
  return world?.value?.extra.quote;
});

const tags = computed(() => {
  return world?.value?.tags || [];
});

const reactions = computed(() => {
  return world?.value?.reaction_counts || [];
});

const userReaction = computed(() => {
  return world?.value?.user_reaction;
});

const userReactionText = computed(() => {
  return worldReactionsNames[get(userReaction.value, 'reaction', '')];
});

const reactionChanged = async (reaction: string, isInstant = false) => {
  await react(reaction, isInstant);
};

const { width: windowWidth } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 500;
});

const worldDropdownToggle = (ev: CustomEvent) => {
  popovers.open(ev, StoryWorldAndSocialSpacePopover, { world: world.value });
};

const isWorldOwner = computed(() => {
  try {
    return (
      (route.name === 'world-draft-details' ? world.value.author.username : world.value.user.username) ===
      user.value.username
    );
  } catch (error) {
    return false;
  }
});
</script>

<style lang="sass" scoped>
.header-popover-button
  position: absolute
  right: 1rem
  bottom: 0.5rem
  height: 24px
  width: 48px
  --border-radius: 16px
  --background: #989aa2
  gap: 10px
  i
    font-size: 1.2rem
    color: white
.inner-actions
  grid-gap: 3px
  @media(max-width: 500px)
    height: 32px
  ::v-deep
    .bookmark
      @media(max-width: 500px)
        height: 32px !important
    ion-button
      font-weight: bold
      height: 40px !important
      margin: 0
      @media(max-width: 500px)
        height: 32px !important
        font-size: 12px
.edit-btn
  min-height: 40px !important
  font-size: 14px
  font-weight: bold
  @media(max-width: 500px)
    height: 32px !important
    min-height: unset !important
    font-size: 12px
.mature
  top: 10px
  left: 10px
  position: absolute

.top-part
  margin-top: -20px
.set-margin
  gap: 5px
.dark .owner
  color: white
.dark .text
  color: #FFF
.dark .story-world-header .rest
  background-color: #17074C !important
  color: #FFF
.actions
  flex-wrap: wrap
.bookmark
  ::v-deep
    ion-button
      height: 40px
      --border-radius: 10px

.one-button
  max-width: 200px
  ::v-deep
    *
      text-transform: unset

@media(max-width: 360px)
  .bookmark
    ::v-deep
      ion-button
        height: 40px !important

@media(max-width: 500px)
  .actions
    grid-gap: 10px
    ::v-deep
      .one-button, .button-solid, .bookmark
        min-width: unset
        flex-grow: 1
        max-width: unset
        margin: 0 !important
.story-world-header
  color: black
  border-radius: 20px
  overflow: hidden
  .cover
    overflow: hidden
    height: 370px
    .image
      object-fit: cover
      width: 100%
      height: 100%
  .rest
    text-align: center
    background: #E6E6E6
    margin-top: -37px
    padding: 30px 24px 12px 24px
  .badge
    height: 20px
  .owner
    margin-top: 24px
    text-align: start
    .weight-500
      font-weight: 700
  .actions
    margin-top: 21px
    width: 85%
    .one-button
      flex-grow: 1
      width: 100%
      ::v-deep
        .reacts, .like-btn
          width: 100%
        .button
          text-transform: unset !important
  .tags
    margin-top: 15px
    width: 80%
  .quote
    margin-top: 24px
    font-weight: 700
    font-size: 16px
    font-style: italic
  .name
    margin-top: 24px
    font-size: 24px
    font-weight: 700
  .tags
    .tag

      border-radius: 5px
      font-weight: bold
      height: 20px
      margin: 0 2.5px
      font-size: 10px
      padding: 0 8px
      cursor: pointer
</style>
