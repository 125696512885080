<template>
  <div class="featured-in">
    <div v-for="(item, index) of featuredIn" :key="index">
      <div v-if="hasValue(item)" class="one-featured-in d-flex align-items-center">
        <div class="type">• {{ itemTitle(item) }}</div>
        <div class="value ml-1">
          <a rel="nofollow" class="link" v-if="item.link" :href="item.link">{{ item.title }}</a>
          <span v-else>{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toTitleSpaceCase } from '@/shared/utils/string';

const props = defineProps({
  value: {
    type: Array,
    default: [],
  },
});

const featuredIn = toRef(props, 'value');

const itemTitle = (item: any) => {
  if (item.type === 'custom') return toTitleSpaceCase(item.key || 'Custom');
  return toTitleSpaceCase(item.type);
};

const hasValue = (featuredIn: any) => {
  return featuredIn.type && featuredIn.title;
};
</script>

<style lang="sass" scoped>
.one-featured-in
  min-height: 30px
  margin-bottom: 10px
  background: #FFF
  border-radius: 10px
  color: #45B6F5
  padding: 0 24px
  .type
    width: 100px
  .value
    font-weight: bold
  a
    color: #45B6F5
.dark
  .one-featured-in, .value
    color: white !important
</style>
