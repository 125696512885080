m
<template>
  <div class="colors d-flex flex-wrap">
    <div v-for="(color, index) of colors" :key="index" class="color" :style="{ background: color }">
      <div class="value">{{ color }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  value: {
    type: Array,
    default: [],
  },
});

const colors = toRef(props, 'value');
</script>

<style lang="sass" scoped>
.colors
  .color
    width: 85px
    height: 85px
    font-size: 10px
    position: relative
    margin-bottom: 30px
    .value
      position: relative
      top: calc(100% + 8px)
      text-align: center
</style>
