<template>
  <div class="actions d-flex justify-content-end">
    <ion-button class="button" @click="mutateAllSections"> {{ text }} </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { storyWorldStore, SectionsCollapsed } from '@/shared/pinia-store/storyworld';
const { collapseSections } = storyWorldStore();

const props = defineProps({
  sectionsCollapsed: {
    default: -1,
  },
});

const sectionsCollapsed = toRef(props, 'sectionsCollapsed');

const isCollapsed = computed(() => {
  const isCollapsed = sectionsCollapsed?.value as SectionsCollapsed;
  return isCollapsed;
});

const text = computed(() => {
  return isCollapsed.value === SectionsCollapsed.noCare || isCollapsed.value === SectionsCollapsed.false
    ? 'COLLAPSE ALL SECTIONS'
    : 'EXPAND ALL SECTIONS';
});

const mutateAllSections = () => {
  const isCollapsed = sectionsCollapsed?.value as SectionsCollapsed;
  const value = isCollapsed === SectionsCollapsed.noCare || isCollapsed === SectionsCollapsed.false ? 1 : 0;
  collapseSections(value);
};
</script>

<style lang="sass" scoped>
.button
  height: 30px
  width: 250px
  --border-radius: 10px
  --background: #8168B7
</style>
